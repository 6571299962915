import React from "react";

import Layout from "../components/layout";
import contactBanner from "../images/contact.svg";
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact"/>
    <div className="flex justify-center">
      <img
        src={contactBanner}
        alt="contact banner"
        className="w-screen h-screen object-cover object-bottom"
      />
    </div>
    <section className="my-20 max-w-6xl mx-auto px-6 lg:px-0">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <div className="py-6">
            <h2 className="font-bold text-3xl py-5">Contact Us</h2>
            <p>
              Contact us for more information. <br />
              We will be happy to hear from you.
            </p>
          </div>
          <div className="flex flex-row items-center space-x-10">
            <div>
              <svg
                className="w-12 text-blue-800 fill-current"
                viewBox="0 0 10.98 15.49"
              >
                <path d="M9.41 1.73h0a5.55 5.55 0 00-7.84 0 5 5 0 00-.45 6.54 14.42 14.42 0 011 1.38l3.45 5.6a.45.45 0 00.63 0l2.39-4.78A22.1 22.1 0 0110.05 8a5 5 0 00-.64-6.27zm-4 5.15h0a2.16 2.16 0 010-4.31h0a2.16 2.16 0 110 4.31z" />
              </svg>
            </div>
            <div className="column">
              <h4 className="uppercase font-bold text-xl">Address</h4>
              <div className="leading-loose">
                <p className="-mb-2">Kupondole</p>
                <p className="-my-2">Lalitpur Municipality - 10</p>
                <p className="-my-2">Lalitpur</p>
                <p className="-my-2">State-3, Nepal</p>
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-10 items-center my-10">
            <div className="column is-narrow">
              <svg
                className="w-12 text-blue-800 fill-current"
                viewBox="0 0 11.95 7.73"
              >
                <path d="M11.76.16h.11v7.05a.44.44 0 01-.44.44H.52a.45.45 0 01-.45-.44v-7L5.85 6h.05zm-.21 0H.26L5.9 5.8z" />
              </svg>
            </div>
            <div className="column">
              <h4 className="uppercase font-bold text-xl">Email</h4>
              <p>
                info@cmteleservices.com.np <br />
                cmteleservices@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div className="column">
          <h2 className="font-bold text-3xl py-5">We are listening.</h2>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="mb-2">
              <label
                htmlFor="name"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Name
              </label>
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-800"
                id="name"
                type="text"
                placeholder="Jane Doe"
                required
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email
              </label>
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-800"
                id="email"
                type="email"
                placeholder="janedoe@example.com"
                required
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="10"
                placeholder="Message"
                className="bg-gray-200 appearance-none border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-800"
                required
              ></textarea>
            </div>
            <div>
              <button
                class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
);

export default Contact;
